<template>
  <div>
    <b-row>
      <b-col>
        <h2>Case {{ actionObj.CaseFileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/edit-action/' + actionObj.ActionID"
        >
          <h2>Action {{ actionObj.ActionFileNumber }}</h2>
        </router-link>
      </b-col>
      <b-col v-if="actionObj.ActionTypeName">
        <div class="gray-rectangle">
          {{ actionObj.ActionTypeName }}
        </div>
      </b-col>
      <b-col />
    </b-row>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + actionObj.CaseID + '/detail/ACTIONS'"
        >
          <h3>Case {{ actionObj.CaseFileNumber }}</h3>
        </router-link>
      </b-col>
      <b-col>
        <h3>Case State: {{ actionObj.CaseStateCode }}</h3>
      </b-col>
      <b-col>
        <h3>Status: In Progress - Investigator</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Add Expense</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="white-bg-container">
          <b-row>
            <b-col md="2">
              <span>Description</span>
            </b-col>
            <b-col>
              <div
                class="collapse-textarea"
                :class="{expanded: !isExpanded}"
              >
                <textarea
                  v-model="Description"
                  class="expandable-textarea"
                  :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  contentEditable
                />
                <div @click="isExpanded = !isExpanded">
                  <span
                    class="expand-arrow mt-n1"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  >&#187;</span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="pb-1 pt-1">
            <b-col md="2">
              <span>Amount</span>
            </b-col>
            <b-col md="2">
              <div class="dollar-icon">
                $
              </div>
              <MoneyInput v-model="Amount" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <span>Date</span>
            </b-col>
            <b-col md="3">
              <b-form-input
                id="dateExpense"
                v-model="ExpenseDate"
                type="date"
                placeholder=""
                name="dateExpense"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pb-2 pt-2">
      <b-col>
        <div class="d-flex">
          <div class="mr-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="resetForm()"
            >
              Cancel
            </b-button>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="processForm()"
            >
              Save
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormInput, BRow} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import MoneyInput from "@core/components/moneyInput/MoneyInput";
import {mapGetters} from "vuex";
import {changeNewItemInCache, checkDatabase, getNewExpense, getSpecialID} from "@core/utils/offlineService";

const apiService = new APIService();

export default {
  components: {
    MoneyInput,
    BRow,
    BCol,
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      actionObj: {},
      activityObj: {},
      ActivityID: "",
      ExpenseID: "",

      Description: "",
      Amount: "0",
      ExpenseDate: "",
      ExpenseCategoryID: 18,

      isExpanded: false,
      onLine: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getOnlineStatus: "offline/getOnlineStatus",
    }),
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
  },
  async created() {
    try {
      this.ActivityID = this.$route.params.activityID;
      this.ExpenseID = this.$route.params.expenseID;
      if (this.ExpenseID) {
        if (String(this.ExpenseID).substring(0, 3) === 'SID') {
          const res = await getNewExpense(this.ExpenseID)
          this.fillExpense(res);
          if (this.ActivityID) {
            this.loadActivity();
          }
          return
        }
        apiService
            .get("activity/expense/" + this.ExpenseID + "/detail")
            .then(res => {
              this.fillExpense(res.data);
              if (this.ActivityID) {
                this.loadActivity();
              }
            })
      }
      if (this.ActivityID) {
        this.loadActivity();
      }
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    loadActivity() {
      apiService
          .get("activity/" + this.ActivityID + "/detail")
          .then(res => {
            this.activityObj = res.data;
            apiService
                .get("action/" + this.activityObj.ActionID + "/detail")
                .then(response => {
                  this.actionObj = response.data;
                })
          })
    },

    fillExpense(data) {
      this.Amount = data.Amount;
      this.ExpenseDate = data.ExpenseDate.substr(0, 10);
      this.Description = data.Description;
      this.ExpenseCategoryID = data.ExpenseCategoryID;
      this.ActivityID = data.ParentActivityID;
    },

    resetForm() {
      this.$router.push('/cases/view-activity/' + this.activityObj.ActivityID + '/EXPENSES')
    },
    processForm() {
      if (!this.ExpenseDate) {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('Must enter a valid Date.'))
        return;
      }

      if (this.Amount === "0") {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('Must enter a valid Amount.'))
        return;
      }

      if (this.Description.length <= 0) {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('Must enter a Description.'))
        return;
      }
      this.Amount = Number(this.Amount).toFixed(2);
      let postData = {
        "ParentActionID": this.activityObj.ActionID,
        "ParentActivityID": this.activityObj.ActivityID,
        "Amount": this.Amount,
        "ExpenseDate": this.ExpenseDate,
        "Description": this.Description,
        "ExpenseCategoryID": this.ExpenseCategoryID,
        "expenseID": this.ExpenseID
      }
      if (!this.onLine && !this.ExpenseID) {
        postData.expenseID = getSpecialID();
      }
      if (this.ExpenseID && String(this.ExpenseID).substring(0, 3) === 'SID') {
        checkDatabase()
            .then(hasDatabase => {
              if (hasDatabase) {
                changeNewItemInCache(postData)
              }
            })
            .catch(error => {
              console.error('Error', error);
            });

        this.showToast("success", "top-center", 4000, "Expense updated");
        this.$router.push('/cases/view-activity/' + this.activityObj.ActivityID + '/EXPENSES')
        return;
      }
      const endpoint = this.ExpenseID ? "activity/expense/" + this.ExpenseID + "/edit" : "activity/expense/create";
      apiService
          .post(endpoint, postData)
          .then(res => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Expense created");
              this.$router.push('/cases/view-activity/' + this.activityObj.ActivityID + '/EXPENSES')
            }
          })
    },
  },
}
</script>

<style scoped>
.dollar-icon {
  position: absolute;
  padding: 0.55rem;
  background-color: #d8d6de;
  color: black;
  border-radius: 0.3rem 0 0 0.3rem;
}
</style>@/@core/utils/offlineService